import PageLoading from 'components/page-loading'
import React from 'react'
import Loadable from 'react-loadable'
import { createRoute } from 'utils/routing'

const loading = () => <PageLoading />

/* Business */

export const checkEmail = createRoute({
  path: '/:locale/check-email',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "check-email" */ 'pages/business/check-email'
      ),
    loading: loading,
  }),
  exact: true,
})

export const notFound = createRoute({
  path: '/:locale/(not-found|404)',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "not-found" */ 'pages/content/not-found'),
    loading: loading,
  }),
})

export const requestPlan = createRoute({
  path: '/:locale/plans/request-:name',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "request-plan" */ 'pages/business/request-plan'
      ),
    loading: loading,
  }),
})

export const user = createRoute({
  path: '/:locale/user',
  component: Loadable({
    loader: () => import(/* webpackChunkName: "user" */ 'pages/business/user'),
    loading: loading,
  }),
})

export const myContent = createRoute({
  path: '/:locale/user',
  exact: true,
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "profile" */ 'pages/business/user/my-content'
      ),
    loading: loading,
  }),
})

export const profile = createRoute({
  path: '/:locale/user/profile',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "profile" */ 'pages/business/user/profile'),
    loading: loading,
  }),
})

export const planDetails = createRoute({
  path: '/:locale/user/plan-details',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "profile" */ 'pages/business/user/plan-details'
      ),
    loading: loading,
  }),
})

export const billingSettings = createRoute({
  path: '/:locale/user/billing-settings',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "billing-settings" */ 'pages/business/user/billing-settings'
      ),
    loading: loading,
  }),
})

export const purchaseHistory = createRoute({
  path: '/:locale/user/purchase-history',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "profile" */ 'pages/business/user/purchase-history'
      ),
    loading: loading,
  }),
})

export const myList = createRoute({
  path: '/:locale/user/my-lists',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName:"my-lists" */ 'pages/business/user/my-lists'),
    loading: loading,
  }),
})

export const myLists = createRoute({
  path: '/:locale/user/my-lists',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "my-lists-list" */ 'pages/business/user/my-lists/list'
      ),
    loading: loading,
  }),
  exact: true,
})

export const myListsView = createRoute({
  path: '/:locale/user/my-lists/:id',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "my-lists-view" */ 'pages/business/user/my-lists/view'
      ),
    loading: loading,
  }),
})

export const members = createRoute({
  path: '/:locale/user/members',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "members" */ 'pages/business/user/members'),
    loading: loading,
  }),
})

export const userLimits = createRoute({
  path: '/:locale/user/user-limits',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "user-limits" */ 'pages/business/user/user-limits'
      ),
    loading: loading,
  }),
})

export const planning = createRoute({
  path: '/:locale/user/planning',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "planning-section" */ 'pages/business/user/planning'
      ),
    loading: loading,
  }),
  exact: true,
})

export const joinTeam = createRoute({
  path: '/:locale/join-team',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "confirmation" */ 'pages/business/confirmation'
      ),
    loading: loading,
  }),
  exact: true,
})

export const finalStep = createRoute({
  path: '/:locale/final-step',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "confirmation" */ 'pages/business/confirmation'
      ),
    loading: loading,
  }),
  exact: true,
})

export const alreadyConfirmed = createRoute({
  path: '/:locale/already-confirmed',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "alredy-confirmed" */ 'pages/business/confirmation/alredy-confirmed'
      ),
    loading: loading,
  }),
  exact: true,
})

export const signin = createRoute({
  path: '/:locale/signin',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "sign-in" */ 'pages/business/sign-in'),
    loading: loading,
  }),
  exact: true,
})

export const signinDeactivated = createRoute({
  path: '/:locale/signin-deactivated',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "sign-in_deactivated" */ 'pages/business/sign-in/deactivated'
      ),
    loading: loading,
  }),
  exact: true,
})

export const signup = createRoute({
  path: '/:locale/signup',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "sign-up" */ 'pages/business/sign-up'),
    loading: loading,
  }),
  exact: true,
})

export const restorePassword = createRoute({
  path: '/:locale/restore-password',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-view" */ 'pages/business/restore-password'
      ),
    loading: loading,
  }),
  exact: true,
})

export const restorePasswordCheck = createRoute({
  path: '/:locale/restore-password/check-email',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-view" */ 'pages/business/restore-password/check-email'
      ),
    loading: loading,
  }),
  exact: true,
})

export const newPassword = createRoute({
  path: '/:locale/new-password',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-view" */ 'pages/business/new-password'
      ),
    loading: loading,
  }),
  exact: true,
})

export const newPasswordChanged = createRoute({
  path: '/:locale/new-password/changed',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-view" */ 'pages/business/new-password/changed'
      ),
    loading: loading,
  }),
  exact: true,
})

export const membersList = createRoute({
  path: '/:locale/user/members',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-list" */ 'pages/business/user/members/list'
      ),
    loading: loading,
  }),
  exact: true,
})

export const membersView = createRoute({
  path: '/:locale/user/members/:id',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "members-view" */ 'pages/business/user/members/view'
      ),
    loading: loading,
  }),
  exact: true,
})

export const payment = createRoute({
  path: '/:locale/payment/:type',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "payment" */ 'pages/business/payment-new'),
    loading: loading,
  }),
  exact: true,
})

export const paymentProcessing = createRoute({
  path: '/:locale/payment-processing',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "payment-processing" */ 'pages/business/payment-processing'
      ),
    loading: loading,
  }),
  exact: true,
})
