import { push, replace } from 'connected-react-router'
import Cookies from 'js-cookie'
import qp from 'query-params-helpers'
import getAcceptedCookies from '../../helpers/cookies'
import * as C from './consts'

export const setLocale = locale => ({
  type: C.SET_LOCALE,
  locale,
})

export const setStrings = strings => ({
  type: C.SET_STRINGS,
  strings,
})

export const setListViewType = value => (d, g) => {
  const cookiesList = getAcceptedCookies()
  if (cookiesList && cookiesList.functional) {
    Cookies.set('list_view_type', value)
  }
  d({
    type: C.SET_LIST_VIEW_TYPE,
    value,
  })
}

export const IsRestrictedContent = value => ({
  type: C.SET_RESTRICTED_CONTENT,
  value,
})

export const toggleMobileMenu = () => ({
  type: C.TOGGLE_MOBILE_MENU,
})

export const toggleMobileFilter = () => ({
  type: C.TOGGLE_MOBILE_FILTER,
})

export const openSignInModal = (email = '') => ({
  type: C.OPEN_SIGNIN_MODAL,
  email,
})

export const closeSignInModal = () => ({
  type: C.CLOSE_SIGNIN_MODAL,
})

export const openCardWarningModal = () => ({
  type: C.OPEN_CARDWARNING_MODAL,
})

export const closeCardWarningModal = () => ({
  type: C.CLOSE_CARDWARNING_MODAL,
})

export const openSignUpModal = () => ({
  type: C.OPEN_SIGNUP_MODAL,
})

export const closeSignUpModal = () => ({
  type: C.CLOSE_SIGNUP_MODAL,
})

export const defineNotFoundPage = path => ({
  type: C.DEFINE_NOT_FOUND,
  path,
})

export const restoreNotFoundPage = path => ({
  type: C.RESTORE_NOT_FOUND,
  path,
})

export const clearPage = value => ({
  type: C.CLEAR_PAGE,
  value,
})

export const landingPage = value => ({
  type: C.LANDING_PAGE,
  value,
})

export const IsFooterSocials = value => ({
  type: C.FOOTER_SOCIALS,
  value,
})

export const wrapClass = value => ({
  type: C.WRAP_CLASS,
  value,
})

export const addPageClass = className => ({
  type: C.ADD_PAGE_CLASS,
  className,
})

export const removePageClass = className => ({
  type: C.REMOVE_PAGE_CLASS,
  className,
})

export const openNewsletterModal = () => ({
  type: C.OPEN_NEWSLETTER_MODAL,
})

export const closeNewsletterModal = () => ({
  type: C.CLOSE_NEWSLETTER_MODAL,
})

export const openTosModal = () => ({
  type: C.OPEN_TOS_MODAL,
})
export const closeTosModal = () => ({
  type: C.CLOSE_TOS_MODAL,
})

export const openNewListModal = () => ({
  type: C.OPEN_NEW_LIST_MODAL,
})

export const closeNewListModal = () => ({
  type: C.CLOSE_NEW_LIST_MODAL,
})

export const openVerifyModal = () => ({
  type: C.OPEN_VERIFY_MODAL,
})

export const closeVerifyModal = () => ({
  type: C.CLOSE_VERIFY_MODAL,
})

export const openRenameListModal = () => ({
  type: C.OPEN_RENAME_LIST_MODAL,
})

export const closeRenameListModal = () => ({
  type: C.CLOSE_RENAME_LIST_MODAL,
})

export const openDuplicateModal = () => ({
  type: C.OPEN_DUPLICATE_MODAL,
})

export const closeDuplicateModal = () => ({
  type: C.CLOSE_DUPLICATE_MODAL,
})

export const openAuthModal = () => ({
  type: C.OPEN_AUTH_MODAL,
})

export const closeAuthModal = () => ({
  type: C.CLOSE_AUTH_MODAL,
})

export const setSearchParams = params => ({
  type: 'SET_SEARCH_PARAMS',
  params: params,
})

export const setCookiesAccepted = cookies => ({
  type: C.SET_COOKIES_ACCEPTED,
  cookies,
})

export const setPendingContent = value => ({
  type: C.SET_PENDING_CONTENT,
  value,
})

export const pushHistory = push
export const replaceHistory = replace

export const updateSearchParams =
  (params = {}, scrollToTop = false, _path = '') =>
  (dispatch, getState) => {
    const path = _path || getState().router.location.pathname

    if (!params.hasOwnProperty('page') && params.hasOwnProperty('pages')) {
      delete params.pages
    }

    const url = qp.stringify(params, path)

    dispatch(
      push(url, {
        scrollToTop,
      })
    )
  }
