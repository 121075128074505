import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { ActionCableConsumer } from 'components/action-cable-wrapper'
import { sendRequest } from 'utils/api'
import { livesView } from 'config/routes'
import { Link } from 'react-router-dom'

class FlashMessage extends Component {
  state = {
    opened: true,
    init: false,
    bodyStyle: {},
  }

  get key() {
    return this.props.data.id + '-' + this.props.data.updated_at
  }

  constructor(props) {
    super(props)

    this.block = React.createRef()
  }

  componentDidMount() {
    if (this.props.windowIsLoaded) {
      this.init()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.windowIsLoaded && this.props.windowIsLoaded) {
      this.init()
    }

    if (
      this.state.init &&
      this.props.data.updated_at !== prevProps.data.updated_at
    ) {
      this.setState(
        {
          bodyStyle: {},
        },
        this.init
      )
    }
  }

  async init() {
    await this.setState({
      bodyStyle: {
        width: this.block.current.offsetWidth - 40,
      },
    })
    await this.setState({
      opened: JSON.parse(localStorage.getItem(this.key) || 'true'),
    })
    await this.setState({
      bodyStyle: {
        width: this.state.bodyStyle.width,
        height: this.block.current.offsetHeight,
      },
      init: true,
    })
  }

  toggle = () => {
    this.setState(
      (state, props) => ({
        opened: !state.opened,
      }),
      () => {
        localStorage.setItem(this.key, JSON.stringify(this.state.opened))
      }
    )
  }

  get linkTo() {
    const {
      locale,
      data: { custom_url, target_link_to, live_event_extid },
    } = this.props
    switch (target_link_to) {
      case 'custom':
        return custom_url
      case 'live_event':
        return livesView.getPath(locale, live_event_extid)
      default:
        return null
    }
  }

  render() {
    const { title, alert_type, target_link_to } = this.props.data
    const { opened, init, bodyStyle } = this.state
    const className = Component.classList(
      'flash-messages__message',
      alert_type === 'red' && 'flash-messages__message--breaking',
      alert_type === 'yellow' && 'flash-messages__message--live',
      init && '_visible',
      init && '_init',
      opened && '_opened'
    )

    const bodyClassName = Component.classList(
      'flash-messages-body _init',
      this.linkTo && '_link'
    )

    return (
      <div className={className} style={{ maxWidth: 'max-content' }}>
        <div className="flash-messages-block" ref={this.block}>
          <span className="flash-messages__arrow" onClick={this.toggle} />
          <div className="flash-messages-inner">
            <div className="flash-messages-head" />
            <div
              className={bodyClassName}
              style={bodyStyle}
              data-target-link-to={target_link_to}
              data-custom-url={this.linkTo}
            >
              <span className="flash-messages-content">
                <span className="flash-messages__title">{title}</span>
              </span>
            </div>
          </div>
          {this.linkTo && target_link_to === 'custom' && (
            <a
              href={this.linkTo}
              className="flash-messages__link"
              data-action-type="alert"
            >
              {title}
            </a>
          )}
          {this.linkTo && target_link_to === 'live_event' && (
            <Link
              to={this.linkTo}
              className="flash-messages__link"
              data-action-type="alert"
            >
              {title}
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export class FlashMessages extends Component {
  state = {
    windowIsLoaded: document.readyState === 'complete',
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({
        windowIsLoaded: true,
      })
    })

    this.getFlashMessages()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.locale !== this.props.locale) {
      this.getFlashMessages()
    }
  }

  getFlashMessages = (callback = () => {}) => {
    sendRequest({
      method: 'GET',
      url: '/api/content/alerts',
    }).then(({ error, body }) => {
      if (!error && body instanceof Array) {
        const msgList = body.reduce(
          (result, item) => ({
            ...result,
            [item.alert_type]: item,
          }),
          {}
        )
        this.setState({ red: void 0, yellow: void 0, ...msgList }, callback)
      }
    })
  }

  onReceived = (msg) => {
    this.getFlashMessages()
  }

  render() {
    const { red, yellow } = this.state
    const { locale } = this.props.common
    return (
      <div className="flash-messages">
        <ActionCableConsumer
          channel="Content::AlertsChannel"
          onReceived={this.onReceived}
        />
        {red && (
          <FlashMessage
            key={red.id + '-' + red.updated_at}
            windowIsLoaded={this.state.windowIsLoaded}
            data={red}
            locale={locale}
          />
        )}
        {yellow && (
          <FlashMessage
            key={yellow.id + '-' + yellow.updated_at}
            windowIsLoaded={this.state.windowIsLoaded}
            data={yellow}
            locale={locale}
          />
        )}
      </div>
    )
  }
}

export default DI()(FlashMessages)
