import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'

import HeaderLangItem from './header-lang-item'

export class HeaderLang extends Component {
  render() {
    const locales =
      Array.isArray(this.props.locales.list.payload) &&
      this.props.locales.list.payload.filter(
        locale => locale.code === 'en' || locale.code === 'ar'
      )

    return (
      <div className="header-lang">
        <div className="iwrap">
          <ul className="header-lang-list">
            {locales.map(locale => {
              return <HeaderLangItem key={locale.code} locale={locale} />
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default DI(['locales', 'common'])(HeaderLang)
