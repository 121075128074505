import * as C from './consts'
import Cookies from 'js-cookie'
import { UAE_REGULATED_CONTENT_LOCALE_KEY } from 'config/constants'

export function getDashboard(search = {}) {
  return {
    types: C.GET_DASHBOARD,
    url: `/api/content/video_projects/dashboard`,
    search,
    hasRestrictedContent: true,
  }
}

export const wsStatusChangeEventDashboard = data => (dispatch, getState) => {
  let lives = [...getState().dashboard.dashboard.payload.live_events]
  const currentItemIndex = lives.findIndex(item => item.id === data.id)

  const restrictedContentAllowedFlagFromUserProfile =
    getState().user.restrictedContentAllowed
  const uaeRegulatedContentFlagFromCookies = Cookies
    ? Cookies.get(UAE_REGULATED_CONTENT_LOCALE_KEY)
    : undefined

  const restrictedContentIsShow =
    typeof uaeRegulatedContentFlagFromCookies !== 'undefined'
      ? uaeRegulatedContentFlagFromCookies === 'true'
      : restrictedContentAllowedFlagFromUserProfile === null
      ? restrictedContentAllowedFlagFromUserProfile
      : false

  if (currentItemIndex > -1) {
    const isUpdate = [
      'confirmed',
      'delayed',
      'ahead',
      'live',
      'next',
      'finished',
    ].includes(data.status)

    const isNeedHide =
      data.front_page_ban ||
      !data.visible ||
      (!restrictedContentIsShow && data.restricted_content)
    const isNeedRemove = !isUpdate || isNeedHide

    if (isNeedRemove) {
      lives.splice(currentItemIndex, 1)
    } else if (isUpdate) {
      lives[currentItemIndex] = data
    }
  } else {
    const isNeedHide =
      data.front_page_ban ||
      !data.visible ||
      (!restrictedContentIsShow && data.restricted_content)
    const isNeedAdded =
      ['confirmed', 'delayed', 'ahead', 'live', 'next'].includes(data.status) &&
      !isNeedHide

    if (isNeedAdded) {
      lives = [data, ...lives]
    }
  }

  lives = lives.sort((a, b) => new Date(a.start_at) - new Date(b.start_at))

  dispatch({
    type: C.GET_DASHBOARD.SUCCESS,
    data: {
      ...getState().dashboard.dashboard.payload,
      live_events: lives,
    },
  })
}
