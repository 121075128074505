// import { ApiAction } from 'redux-upgrader';

export const SET_LOCALE = 'SET_LOCALE'
export const SET_LIVE_LOCALE = 'SET_LIVE_LOCALE'
export const SET_STRINGS = 'SET_STRINGS'
export const SET_RESTRICTED_CONTENT = 'SET_RESTRICTED_CONTENT'
export const SET_PENDING_CONTENT = 'SET_PENDING_CONTENT'
export const SET_LIST_VIEW_TYPE = 'SET_LIST_VIEW_TYPE'

export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'
export const TOGGLE_MOBILE_FILTER = 'TOGGLE_MOBILE_FILTER'

export const CLOSE_CARDWARNING_MODAL = 'CLOSE_CARDWARNING_MODAL'
export const OPEN_CARDWARNING_MODAL = 'OPEN_CARDWARNING_MODAL'
export const OPEN_SIGNIN_MODAL = 'OPEN_SIGNIN_MODAL'
export const CLOSE_SIGNIN_MODAL = 'CLOSE_SIGNIN_MODAL'
export const OPEN_SIGNUP_MODAL = 'OPEN_SIGNUP_MODAL'
export const CLOSE_SIGNUP_MODAL = 'CLOSE_SIGNUP_MODAL'
export const DEFINE_NOT_FOUND = 'DEFINE_NOT_FOUND'
export const RESTORE_NOT_FOUND = 'RESTORE_NOT_FOUND'
export const OPEN_NEWSLETTER_MODAL = 'OPEN_NEWSLETTER_MODAL'
export const CLOSE_NEWSLETTER_MODAL = 'CLOSE_NEWSLETTER_MODAL'
export const OPEN_TOS_MODAL = 'OPEN_TOS_MODAL'
export const CLOSE_TOS_MODAL = 'CLOSE_TOS_MODAL'

export const OPEN_NEW_LIST_MODAL = 'OPEN_MY_LISTS_MODAL'
export const CLOSE_NEW_LIST_MODAL = 'CLOSE_NEW_LIST_MODAL'

export const OPEN_RENAME_LIST_MODAL = 'OPEN_RENAME_LIST_MODAL'
export const CLOSE_RENAME_LIST_MODAL = 'CLOSE_RENAME_LIST_MODAL'

export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'

export const OPEN_VERIFY_MODAL = 'OPEN_VERIFY_MODAL'
export const CLOSE_VERIFY_MODAL = 'CLOSE_VERIFY_MODAL'

export const OPEN_DUPLICATE_MODAL = 'OPEN_DUPLICATE_MODAL'
export const CLOSE_DUPLICATE_MODAL = 'CLOSE_DUPLICATE_MODAL'

export const CLEAR_PAGE = 'CLEAR_PAGE'
export const LANDING_PAGE = 'LANDING_PAGE'
export const FOOTER_SOCIALS = 'FOOTER_SOCIALS'
export const WRAP_CLASS = 'WRAP_CLASS'

export const ADD_PAGE_CLASS = 'ADD_PAGE_CLASS'
export const REMOVE_PAGE_CLASS = 'REMOVE_PAGE_CLASS'

export const TOGGLE_WEBPUSH_SETTINGS_POPUP = 'TOGGLE_WEBPUSH_SETTINGS_POPUP'
export const SET_COOKIES_ACCEPTED = 'SET_COOKIES_ACCEPTED'
