import * as C from '../consts'

export function getLists(search = {}) {
  return {
    types: C.GET_LISTS,
    method: 'GET',
    url: '/api/content/lists',
    search,
    hasRestrictedContent: true,
  }
}

export function getVideoLists(search = {}) {
  return {
    types: C.GET_VIDEO_LISTS,
    method: 'GET',
    url: '/api/content/video_lists',
    search,
    hasRestrictedContent: true,
  }
}

export function getListItems(search = {}, checkRestrictedContent = true) {
  const action = {
    types: C.GET_LIST_ITEMS,
    method: 'GET',
    url: '/api/content/list_items',
    search,
  }

  return checkRestrictedContent
    ? { ...action, hasRestrictedContent: true }
    : action
}

export function getActiveList(id) {
  return {
    types: C.GET_ACTIVE_LIST,
    method: 'GET',
    url: `/api/content/lists/${id}`,
    hasRestrictedContent: true,
  }
}

export function createList(user_id, title, items_list, video_obj) {
  return {
    types: C.GET_ACTIVE_LIST,
    method: 'POST',
    url: `/api/content/lists`,
    body: {
      user_id,
      title,
      list_connection_ids: items_list,
      content_objects: video_obj,
    },
  }
}

export function updateList(list_id, user_id, title, list_access) {
  return {
    types: C.UPDATE_LIST,
    method: 'PATCH',
    url: `/api/content/lists/${list_id}`,
    body: { user_id, title, is_public: list_access },
  }
}

export function deleteList(id) {
  return {
    types: C.DELETE_LIST,
    method: 'DELETE',
    url: `/api/content/lists/${id}`,
  }
}

export function addListItem(item_type, item_id, list_id) {
  return {
    types: C.ADD_LIST_ITEM,
    method: 'POST',
    url: `/api/content/list_items`,
    body: {
      item_type,
      item_id,
      list_id,
    },
  }
}

export function deleteListItem(list_id, items) {
  return {
    types: C.DELETE_LIST_ITEM,
    method: 'DELETE',
    url: `/api/content/list_items`,
    body: {
      list_id,
      ids: items,
    },
  }
}
