import React from 'react'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import Form from 'common/components/form/new-form'
import Component from 'components/component'
import { Link } from 'components/link'
import * as routes from 'config/routes'
import { DI } from 'modules'
import Dropdown from 'components/dropdown'
import * as config from 'config'

let counter = 0

export class SearchForm extends Component {
  static defaultProps = {
    searchRoute: routes.videosList,
    searchRouteParams: null,
    classNamePrefix: 'dashboard-search',
    suggestsEnabled: true,
    onChangeFocus(focus) {},
    onSearch() {},
    onReset() {},
    isUserContent: false,
  }

  id = counter++

  state = {
    search: this.props.common.searchParams.search || '',
    focus: false,
    pillType:
      this.props.common.isMobile || this.props.isUserContent ? 'this_page' : '',
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.common.searchParams.search &&
      !this.props.common.searchParams.search
    ) {
      this.setState({
        search: '',
      })
    }
  }

  _updateSearchParams = () => {
    const { searchRouteParams, common, actions, searchRoute } = this.props
    const { search, page, ...params } = common.searchParams
    const currentRoute =
      this.state.pillType === '' ? routes.videosList : searchRoute
    params.search = this.state.search
    actions.common.updateSearchParams(
      params,
      common.isMobile,
      currentRoute.getPath(
        ...Object.values(searchRouteParams || { locale: common.locale })
      )
    )
  }

  _getSuggests = val => {
    if (
      this.props.suggestsEnabled ||
      (this.props.withPill && this.state.pillType === '')
    ) {
      clearTimeout(this.timeotId)
      this.timeotId = setTimeout(() => {
        this.props.actions.videos.getSuggests({ search: val })
      }, 500)
    }
  }

  onChange = ev => {
    const search = ev.target.value
    if (search !== ' ') {
      this.setState(
        { search },
        () => search.length > 3 && this._getSuggests(search)
      )
    }
  }

  onSubmit = () => {
    if (this.state.search) {
      clearTimeout(this.timeotId)
      ReactGA.event({
        category: this.props.location.pathname,
        action: 'Find',
        label: this.state.search,
      })

      this._updateSearchParams()
      this.input.blur()
      this.props.onSearch()
    } else {
      this.onReset()
    }
  }

  onReset = () => {
    const { common, actions, onReset } = this.props
    const { search, order, page, ...params } = common.searchParams

    actions.common.updateSearchParams(params, common.isMobile)
    this.setState({
      search: '',
    })
    onReset()
  }

  onFocus = ev => {
    this.setState(
      {
        focus: true,
      },
      () => {
        this.props.onChangeFocus(this.state.focus)
      }
    )
  }

  onBlur = ev => {
    this.setState(
      {
        focus: false,
        value: '',
      },
      () => {
        this.props.onChangeFocus(this.state.focus)
      }
    )
  }

  onChangeOrder = value => ev => {
    ev.preventDefault()
    this.setState({ pillType: value })
  }

  render() {
    const { strings, isMobile, locale } = this.props.common
    const { pillType, search = '' } = this.state
    const suggests = this.props.videos.suggestsBySearch.getValue(search)

    const resetClassName = this.classList(
      `${this.props.classNamePrefix}__reset`,
      search && '_vis'
    )

    return (
      <Form
        onSubmit={this.onSubmit}
        onReset={this.onReset}
        className={this.classList(
          this.props.classNamePrefix,
          suggests.pending && '_loading',
          this.state.focus && '_focused',
          this.props.visible && '_vis'
        )}
        formClassName={this.classList(
          !isMobile ? 'tools-search' : 'is-mobile',
          'tools-search',
          this.props.withPill && !isMobile && 'tools-search-landing'
        )}
        // style={{display: 'none'}}
      >
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              'url': config.VOD_HOST,
              'potentialAction': {
                '@type': 'SearchAction',
                'target': {
                  '@type': 'EntryPoint',
                  'urlTemplate': `${config.VOD_HOST}/${locale}/videos?search={search}`,
                },
                'query-input': 'required name=search',
              },
            })}
          </script>
        </Helmet>
        {this.props.withPill && !isMobile && (
          <Dropdown
            className="tools-search__pill"
            headerModificator="small tools-search__pill--label"
            items={[
              {
                title: strings['vods.search.pill.everywhere'],
                value: '',
              },
              {
                title: strings['vods.search.pill.this_page'],
                value: 'this_page',
              },
            ]}
            value={pillType}
            onChange={(ev, value) => this.onChangeOrder(value)(ev)}
          />
        )}

        <label htmlFor={`search-input-${this.id}`} />
        <input
          type="text"
          placeholder={
            isMobile
              ? this.props.common.strings['vods.search.placeholder_mobile']
              : this.props.common.strings['vods.search.placeholder']
          }
          className={`${this.props.classNamePrefix}__input`}
          name="search"
          autoFocus={this.props.autoFocus}
          value={search}
          onChange={this.onChange}
          onFocus={this.onFocus}
          ref={el => (this.input = el)}
          id={`search-input-${this.id}`}
          onBlur={this.onBlur}
        />
        <button
          className={resetClassName}
          type="reset"
          onClick={this.onReset}
        />
        <button
          className={`button ${this.props.classNamePrefix}__submit`}
          type="submit"
        >
          {strings['vods.search.submit']}
        </button>
        {this.state.pillType === '' && (
          <div
            className={this.classList(
              `${this.props.classNamePrefix}-body`,
              this.state.focus && '_vis'
            )}
          >
            <ul className={`${this.props.classNamePrefix}-list`}>
              {suggests.payload.videos.map(video => (
                <li
                  key={video.id}
                  className={`${this.props.classNamePrefix}-item`}
                >
                  <Link
                    to={routes.videoView.getPath('', video.id)}
                    onClick={this.props.actions.videos.clearSuggests}
                    className={`${this.props.classNamePrefix}__link`}
                  >
                    {video.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Form>
    )
  }
}

export default DI(['videos'])(withRouter(SearchForm))
