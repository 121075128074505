import React from 'react'
import PageLoading from 'components/page-loading'
import Loadable from 'react-loadable'

import { createRoute } from 'utils/routing'

const loading = () => <PageLoading />

/* Static  */

export const choosePlan = createRoute({
  path: '/:locale/plans',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "choose-plan" */ 'pages/static/choose-plan'),
    loading: loading,
  }),
  exact: true,
})

// export const impressum = createRoute({
//   path: '/:locale/impressum',
//   component: Loadable({
//     loader: () =>
//       import(/* webpackChunkName: "choose-plan" */ 'pages/static/impressum'),
//     loading: loading,
//   }),
//   exact: true,
// })

export const companyDetails = createRoute({
  path: '/:locale/company-details',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "choose-plan" */ 'pages/static/company-details'
      ),
    loading: loading,
  }),
  exact: true,
})

// RPTL - 94
// export const broadcast = createRoute({
//     path: '/:locale/broadcast-services',
//     component: Loadable({
//         loader: () =>
//             import(
//                 /* webpackChunkName: "broadcast-services" */ 'pages/static/broadcast'
//             ),
//         loading: loading,
//     }),
//     exact: true,
// });

// export const broadcastLocation = createRoute({
//     path: '/:locale/broadcast-services/:location',
//     component: Loadable({
//         loader: () =>
//             import(
//                 /* webpackChunkName: "broadcast-services-locale" */ 'pages/static/broadcast/location'
//             ),
//         loading: loading,
//     }),
//     exact: true,
// });

export const about = createRoute({
  path: '/:locale/about',
  component: Loadable({
    loader: () => import(/* webpackChunkName: "about" */ 'pages/static/about'),
    loading: loading,
  }),
  // exact: true
})

export const tos = createRoute({
  path: '/:locale/terms-of-use',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "tos" */ 'pages/static/terms-of-use'),
    loading: loading,
  }),
  exact: true,
})

export const tosAgreement = createRoute({
  path: '/:locale/terms-of-use/:idagreement',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "tos-agreement" */ 'pages/static/terms-of-use-agreement'
      ),
    loading: loading,
  }),
  exact: true,
})

export const privacyPolicy = createRoute({
  path: '/:locale/privacy-policy',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ 'pages/static/privacy-policy'
      ),
    loading: loading,
  }),
  exact: true,
})

export const cookiePolicy = createRoute({
  path: '/:locale/cookie-policy',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "cookie-policy" */ 'pages/static/cookie-policy'
      ),
    loading: loading,
  }),
  exact: true,
})

export const careersList = createRoute({
  path: '/:locale/career',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "careers-list" */ 'pages/static/careers/careers-list'
      ),
    loading: loading,
  }),
  exact: true,
})

export const careersView = createRoute({
  path: '/:locale/career/:id',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "careers-view" */ 'pages/static/careers/careers-view'
      ),
    loading: loading,
  }),
  exact: true,
})

export const oldbrowsersView = createRoute({
  path: '/:locale/old-browsers',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "old-browsers" */ 'pages/static/oldbrowsers'),
    loading: loading,
  }),
  exact: true,
})
