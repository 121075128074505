import 'submodules/responsivestyles/src/less/popup.less'
// Styles
import 'submodules/responsivestyles/src/less/shared.less'
import 'submodules/responsivestyles/src/less/sign-in.less'
// Components
import { ActionCableConsumer } from 'components/action-cable-wrapper'
import FlashMessages from 'components/flash-messages'
import Footer from 'components/footer'
import Header from 'components/header'
import { Link } from 'components/link'
import Notifications from 'components/notifications'
import ReleaseNotes from 'components/release-notes'
import ModalSpinner from 'components/spinner'
import ToS from 'components/tos'
import WebPush from 'components/web-push'
// Config
import * as routes from 'config/routes'
import * as config from 'config'
// Modules
import { DI } from 'modules'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import LiveChat from 'react-livechat'
// Routes
import Routes from 'routes'
import { withRouter } from 'react-router-dom'
// Store
import { isServer } from 'store'
import { VOD_HOST } from 'config'
// Utils
import { translations } from 'middlewares/localizer'
import ModalNewslettersToPardot from './components/newsletters-to-pardot-modal/modalNewslettersToPardot'

const App = ({ actions, common, locales, router, user }) => {
  // Setup
  const trs = React.useRef({ ...translations })
  const { pathname, state } = router.location

  // Effects
  React.useEffect(() => {
    if (!common.isIE) {
      const { localStorage } = window

      try {
        const notAdmin = JSON.parse(localStorage.getItem('notAdmin') || 'false')

        if (notAdmin) {
          actions.notifications.createNotification({
            type: 'error',
            children: common.strings['header.error.not_admin'],
          })

          localStorage.removeItem('notAdmin')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [actions, common])

  React.useEffect(() => {
    if (window.VideoTags) {
      trs.current = { ...trs.current, [common.locale]: common.strings }
      window.VideoTags.config = {
        locale: common.locale,
        isSignedIn: user.isSignedIn,
        pathname,
        translations: trs.current,
        prevPage: (state || { prevPage: '' }).prevPage,
      }
    }
  }, [common.strings, user.isSignedIn, pathname, window.VideoTags])

  // Handlers
  const handleReceived = React.useCallback(
    message => {
      actions.user.wsChangeRequest(message.data)
      actions.notifications.createNotification({
        type: 'success',
        children: (
          <Link to={routes.myContent.getPath()}>
            {common.strings['page.my_content.notifications.request_completed']}
          </Link>
        ),
      })
    },
    [actions, common]
  )

  return (
    <>
      {!common.isIE && (
        <ActionCableConsumer
          channel="Content::VideoProjects::TranscodingChannel"
          onReceived={handleReceived}
        />
      )}
      <Helmet
        htmlAttributes={{
          dir: common.locale === 'ar' ? 'rtl' : 'ltr',
          lang: common.locale,
        }}
      >
        {Array.isArray(locales.list.payload) &&
          locales.list.payload.map(locale => (
            <link
              key={locale.code}
              href={`${VOD_HOST}${pathname.replace(
                common.locale,
                locale.code
              )}`}
              hrefLang={locale.code}
              rel="alternate"
            />
          ))}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'url': config.VOD_HOST,
            'logo': `${config.STORAGE_HOST}/logo/logo.png`,
          })}
        </script>
      </Helmet>

      {!common.isIE && (
        <React.Fragment>
          <Notifications />
          <ToS />
          <ReleaseNotes />
          <ModalNewslettersToPardot />
          <Header />
        </React.Fragment>
      )}

      <Routes />

      {!common.isIE && (
        <React.Fragment>
          <WebPush />
          <Footer />
        </React.Fragment>
      )}

      {!isServer && (
        <>
          {!common.isIE && <FlashMessages locale={common.locale} />}
          <LiveChat license={10100303} />
        </>
      )}
      <ModalSpinner />
    </>
  )
}

export default withRouter(
  DI(['common', 'user', 'notifications', 'tos', 'locales', 'router'])(App)
)
