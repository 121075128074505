import { SET_MAIN_MENU, SET_USER_MENU, SET_ABOUT_MENU } from './consts'
import { GET_TAGS } from 'modules/tags/consts'
import { SET_STRINGS } from 'modules/common/consts'
import { GET_CURRENT_USER } from 'modules/user/consts'
import { mainMenu, userMenu, aboutMenu } from './config'
import { batch } from 'react-redux'
import { GET_AGREEMENTS } from '../user/consts'

function filter(state) {
  return (item) => {
    if (typeof item.disabled === 'function') {
      return !item.disabled(state)
    } else {
      if (item.disabled) {
        return false
      } else {
        return true
      }
    }
  }
}

const prepareItem = ({ item, strings, tags, locale, pathname }, state) => {
  const title = item.titleKey
    ? strings[item.titleKey] || item.title
    : item.title
  const slug = item.tag || item.category
  const exact = item.exact || false
  let path = `${item.path || ''}`
  let search = item.search || ''
  let isLocale = item.locale || false
  let origin = item.origin || ''

  if (slug) {
    // const { id } = tags.find((tag) => tag.slug === slug) || {};
    path += '/filter/' + slug
    // if (id) {
    //     let name = item.tag ? 'tags_id_in' : 'categories_id_in';
    //     search = `?q[${name}][]=${id}`;
    // }
  }

  if (origin) {
    item.origin = origin
  }

  const active = Boolean(
    exact
      ? path === pathname.replace(/^\/\w{2}\//, '/')
      : pathname.replace(/^\/\w{2}\//, '/').match(path)
  )

  const link = path + search

  return {
    title,
    link: origin
      ? origin + (isLocale ? locale : '') + link
      : isLocale
      ? link + locale
      : link,
    origin,
    localeLink: '/' + locale + link,
    path,
    search,
    active,
    exact,
    disabled: item.disabled,
    children: (item.children || []).filter(filter(state)).map((subitem) =>
      prepareItem(
        {
          item: subitem,
          strings,
          tags,
          locale,
          pathname,
        },
        state
      )
    ),
  }
}

export default ({ getState, dispatch }) => {
  function updateMenu(type, menu, params = {}, reason) {
    const state = getState()

    const {
      strings = state.common.strings,
      tags = state.tags.list.payload,
      pathname = state.router.location.pathname,
      locale = state.common.locale,
    } = params

    dispatch({
      type,
      reason,
      menu: menu.filter(filter(state)).map((item) =>
        prepareItem(
          {
            item,
            strings,
            tags,
            locale,
            pathname,
          },
          state
        )
      ),
    })
  }

  return (next) => (action) => {
    switch (action.type) {
      case '@@router/LOCATION_CHANGE': {
        const params = {
          location: action.payload.location.pathname,
        }
        setTimeout(() => {
          batch(() => {
            updateMenu(SET_MAIN_MENU, mainMenu, params, 'location')
            updateMenu(SET_ABOUT_MENU, aboutMenu, params, 'location')
            updateMenu(SET_USER_MENU, userMenu, params, 'location')
          })
        }, 0)

        return next(action)
      }
      case GET_TAGS.SUCCESS: {
        const tags = action.data.map((tag) => {
          if (!tag?.meta_tag) return tag

          const newTag = {
            ...tag,
            meta_tag: {
              title: tag.meta_tag?.title
                ? tag.meta_tag.title.replace(/ruptly/gi, ' ')
                : null,
              description: tag.meta_tag?.description
                ? tag.meta_tag.description.replace(/ruptly/gi, ' ')
                : null,
            },
          }

          return newTag
        })

        const params = {
          tags,
        }
        setTimeout(() => {
          batch(() => {
            updateMenu(SET_MAIN_MENU, mainMenu, params, 'tags')
            // updateMenu(SET_MAIN_MENU_MORE, mainMenuMore, params, 'tags');
          })
        }, 0)
        return next(action)
      }
      case SET_STRINGS: {
        const params = {
          strings: action.strings,
        }
        setTimeout(() => {
          batch(() => {
            updateMenu(SET_MAIN_MENU, mainMenu, params, 'strings')
            updateMenu(SET_ABOUT_MENU, aboutMenu, params, 'strings')
            updateMenu(SET_USER_MENU, userMenu, params, 'strings')
          })
        }, 0)
        return next(action)
      }
      case GET_CURRENT_USER.SUCCESS: {
        setTimeout(() => {
          batch(() => {
            updateMenu(SET_MAIN_MENU, mainMenu, {}, 'user')
            updateMenu(SET_ABOUT_MENU, aboutMenu, {}, 'user')
            updateMenu(SET_USER_MENU, userMenu, {}, 'user')
          })
        }, 0)
        return next(action)
      }

      case GET_AGREEMENTS.SUCCESS: {
        setTimeout(() => {
          batch(() => {
            updateMenu(SET_MAIN_MENU, mainMenu, {}, 'user')
          })
        }, 0)
        return next(action)
      }

      // case SET_LOCALE: {
      //     const params = {
      //         locale: action.locale
      //     };
      //     setTimeout(() => {
      //         updateMenu(SET_MAIN_MENU, mainMenu, params, 'locale');
      //         updateMenu(SET_MAIN_MENU_MORE, mainMenuMore, params, 'locale');
      //         updateMenu(SET_USER_MENU, userMenu, params, 'locale');
      //     }, 0);
      //     return next(action);
      // }
      default: {
        return next(action)
      }
    }
  }
}
