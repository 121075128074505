import React, { useState } from 'react'
import { DI } from 'modules'
import { Modal } from 'components/modal'
import Form from 'common/components/form/new-form'
import { TextInput } from 'components/form/text-input'

const ModalAuthComponent = props => {
  const [chars, setChars] = useState('')
  const [error, setError] = useState()
  const [clientIP, setClientIP] = useState('')

  const { strings } = props.common

  const charsCounter = () => {
    const charCount = event.target.value
    setChars(charCount)
  }

  const onReset = () => {
    setChars('')
    props.close()
  }

  const onSubmit = () => {
    props.actions.user
      .adminVerify({ token: chars })
      .then(() => {
        props.confirm()
        props.close()
        props.actions.user.checkPlanningAccess().catch(() => {})
      })
      .catch(err => {
        const {
          status,
          response: { body },
        } = err

        if (status) {
          setError(status)
          if (status === 403) {
            setClientIP(body.client_ip)
          }
        }
        throw err
      })
  }

  return (
    <Modal>
      <Form onReset={onReset} onSubmit={onSubmit}>
        <div className="popup-with-ico">
          <span className="popup__title">
            {strings['header.profile.two_auth.title']}
          </span>
          <div className="sign-in-text sign-in-text--pre">
            <p>{strings['header.profile.two_auth.description']}</p>
          </div>
          <div className="popup-field">
            <TextInput
              isRequired
              maxLength={4}
              placeholder={strings['header.profile.two_auth.placeholder']}
              name="token"
              value={chars}
              onChange={charsCounter}
            />
            {/*<span class="popup-field__counter">{`${chars.length}/4`}</span>*/}
            {error && (
              <div className="error">
                {error == 404 && strings['header.profile.two_auth.error']}
                {error == 403 &&
                  strings['header.profile.two_auth.forbidden'] + ` ${clientIP}`}
              </div>
            )}
          </div>

          <div className="popup-buttons popup-buttons--ico">
            <div className="popup-button">
              <button type="reset" className="text-button">
                {strings['page.request_enterprise.form.cancel']}
              </button>
            </div>
            <div className="popup-button">
              <button type="submit" className="button">
                {strings['page.profile.form.pass.change_password.confirm']}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export const ModalAuth = DI(['user'])(ModalAuthComponent)
