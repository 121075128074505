import Cookies from 'js-cookie'
import { Settings } from 'luxon'
import { setStrings } from 'modules/common/actions'
import * as C from 'modules/common/consts'
// import DEPRECATED_moment from 'moment-timezone';
import { toggleChatWindow } from 'utils/livechat'
import getAcceptedCookies from '../../helpers/cookies'
// import 'moment/locale/ru';
// import 'moment/locale/es';
// import 'moment/locale/ar-kw';

const isServer = process.env.isServer

export const STOTAGE_LOCALE_KEY = 'language_code'

let en = require('../../submodules/translations/eng.json')
let es = {}
let ru = {}
let ar = {}

const loaders = {
  en: () =>
    import(
      /* webpackChunkName: "eng.json" */ '../../submodules/translations/eng.json'
    ),
  es: () => Promise.reject(),
  ru: () => Promise.reject(),
  ar: () =>
    import(
      /* webpackChunkName: "ara.json" */ '../../submodules/translations/ara.json'
    ),
}

export const translations = {
  en: en,
  es: { ...en, ...es },
  ru: { ...en, ...ru },
  ar: { ...en, ...ar },
}

const momentLocales = {
  ru: 'ru',
  es: 'es',
  ar: 'ar',
}

export default ({ getState, dispatch }) => {
  // moment.locale(getState().common.locale);
  Settings.defaultLocale = getState().common.locale
  return next => action => {
    const cookiesList = getAcceptedCookies()
    if (action.type === C.SET_LOCALE) {
      // moment.locale(momentLocales[action.locale] || action.locale);
      Settings.defaultLocale = momentLocales[action.locale] || action.locale
      if (isServer) {
        let strings = translations[action.locale]
        dispatch(setStrings(Object.assign({}, en, strings)))
      } else {
        loaders[action.locale]()
          .catch(error => loaders.en())
          .then(data => {
            dispatch(setStrings(Object.assign({}, en, data)))
          })
      }

      if (cookiesList && cookiesList.functional) {
        Cookies.set(STOTAGE_LOCALE_KEY, action.locale, { expires: 365 })
      }
      toggleChatWindow()
      return next(action)
    } else {
      return next(action)
    }
  }
}

function checkLocale(locale) {
  if (translations.hasOwnProperty(locale)) {
    return locale
  } else {
    try {
      let savedLocale = window.localStorage.getItem(STOTAGE_LOCALE_KEY)
      let defaultLocale = window.navigator.language.match(/^[a-z]{2}/)

      if (savedLocale) {
        if (translations.hasOwnProperty(savedLocale)) {
          return savedLocale
        }
      }

      if (defaultLocale) {
        defaultLocale = defaultLocale[0]

        if (translations.hasOwnProperty(defaultLocale)) {
          return defaultLocale
        }
      }
    } catch (err) {
      return 'en'
    }
  }
  return 'en'
}

export function getLocale() {
  let locale
  try {
    locale = document.location.pathname.match(/^\/(\w{2})(\/|$)/)[1]
  } catch (err) {
    locale = 'en'
  }

  return checkLocale(locale)
}
