import {
  videosList,
  videosFilter,
  livesList,
  myEventsList,
  archivedLivesList,
  myContent,
  profile,
  planDetails,
  billingSettings,
  purchaseHistory,
  myList,
  members,
  userLimits,
  planning,
} from 'config/routes'
import * as config from '../../config'
import { recordingsList } from '../../config/routes'

export const aboutMenu = [
  {
    path: '/about',
    titleKey: 'page.about.submenu.about',
  },
]

export const mainMenu = [
  {
    path: videosList.getPath(),
    titleKey: 'header.menu.vod',
    children: [
      {
        titleKey: 'header.menu.vod.latest',
        path: videosList.getPath(),
        exact: true,
      },
      {
        titleKey: 'header.menu.vod.sports',
        path: videosFilter.getPath(),
        category: 'sports',
        exact: true,
      },
      // {
      //     titleKey: 'header.menu.vod.close_up',
      //     path: videosFilter.getPath(),
      //     tag: 'closeup',
      //     exact: true,
      // },

      {
        titleKey: 'header.menu.vod.lightnews',
        path: videosFilter.getPath(),
        category: 'light-news',
        exact: true,
      },
      // {
      //     titleKey: 'header.menu.vod.360',
      //     path: videosFilter.getPath('', '360'),
      //     search: '?q[format_eq]=1',
      //     exact: true,
      // },
      /*{
        titleKey: 'header.menu.vod.partner_content',
        path: videosFilter.getPath(),
        tag: 'partner-content',
        exact: true,
      },*/
    ],
  },
  {
    path: livesList.getPath(),
    titleKey: 'header.menu.live',
    exact: true,
    children: [
      {
        titleKey: 'header.menu.live.all_events',
        path: livesList.getPath(),
        exact: true,
        private: true,
      },
      {
        titleKey: 'header.menu.live.my_events',
        path: myEventsList.getPath(),
        exact: true,
        private: true,
      },
      {
        titleKey: 'header.menu.live.recordings',
        path: recordingsList.getPath(),
        exact: true,
        private: true,
        disabled: (state) => {
          return hasNotRecordingsAccess(state)
        },
      },
      /* {
        titleKey: 'header.menu.live.archived_events',
        path: archivedLivesList.getPath(),
        exact: true,
        private: true,
      }, */
    ],
  },
  // По просьбе Азиза 13 07 2022 RPTL - 94
  // {
  //   path: '/broadcast-services',
  //   titleKey: 'header.menu.broadcast',
  // },
  {
    path: config.VU_HOST,
    titleKey: 'header.menu.verification',
    exact: true,
    locale: true,
    disabled: true,
    children: [
      {
        path: config.VU_HOST,
        titleKey: 'header.menu.verification.blog',
        exact: true,
        locale: true,
      },
      {
        path: '/about-us/',
        titleKey: 'header.menu.verification.about_vu',
        exact: true,
        locale: true,
        origin: config.VU_HOST,
      },
    ],
  },
  {
    path: '/plans',
    titleKey: 'header.menu.pricing',
  },
  {
    path: config.MEDIA_CENTRE,
    titleKey: 'header.menu.mediacentre',
    exact: true,
    disabled: true,
  },
  {
    path: '/about',
    titleKey: 'footer.link.about',
    exact: true,
    children: [
      {
        path: '/about',
        titleKey: 'header.menu.about',
        exact: true,
      },
      // {
      //   path: '/career',
      //   titleKey: 'header.menu.careers',
      //   exact: true,
      // },
    ],
  },
]

export const isClient = (state) => {
  return state.user.currentUser.payload.user_group === 'client'
}

export const hasNotAgreementsOrMembers = (state) => {
  return (
    state.user.userLimitsAgreements.payload.length <= 0 ||
    state.user.currentUser.payload.members_count <= 0
  )
}

export const hasNotRecordingsAccess = (state) => {
  return !(state.user.agreements.payload || []).some(
    (agr) => agr.status === 'active' && agr.recordings_access
  )
}

export const noHasMembers = (state) => {
  const user = state.user.currentUser.payload

  return user.members_count <= 0 && user.only_one_off
}

export const userMenu = [
  {
    titleKey: 'page.profile.menu.my_content',
    path: myContent.getPath(),
    exact: true,
  },
  {
    titleKey: 'page.profile.menu.profile',
    path: profile.getPath(),
  },
  {
    titleKey: 'page.profile.menu.plan_details',
    path: planDetails.getPath(),
  },
  {
    titleKey: 'page.profile.menu.billing_settings',
    path: billingSettings.getPath(),
    disabled: isClient,
  },
  {
    titleKey: 'page.profile.menu.purchase_history',
    path: purchaseHistory.getPath(),
    disabled: isClient,
  },
  {
    titleKey: 'page.profile.menu.my_lists',
    path: myList.getPath(),
  },
  {
    titleKey: 'page.profile.menu.members',
    path: members.getPath(),
    disabled: (state) => {
      return isClient(state) || noHasMembers(state)
    },
  },
  {
    titleKey: 'page.profile.menu.user_limits',
    path: userLimits.getPath(),
    disabled: (state) => {
      return (
        isClient(state) ||
        noHasMembers(state) ||
        hasNotAgreementsOrMembers(state)
      )
    },
  },
  {
    titleKey: 'page.profile.menu.planning',
    path: planning.getPath(),
    disabled: (state) => !!state.user.planningAccess.error,
  },
]
