import React from 'react'
import PageLoading from 'components/page-loading'
import Loadable from 'react-loadable'

import { createRoute } from 'utils/routing'
import * as config from 'config'

const loading = () => <PageLoading />

/* Content */

export const dashboard = createRoute({
  path: '/:locale',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "dashboard" */ 'pages/content/dashboard'),
    loading: loading,
  }),
  exact: true,
})

export const landing = createRoute({
  path: '/:locale/landing/:id',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lives-my-events" */ 'pages/content/landing'),
    loading: loading,
  }),
  exact: true,
})

export const livesList = createRoute({
  path: '/:locale/events',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lives-list" */ 'pages/content/lives/list'),
    loading: loading,
    render(loaded, props) {
      let Component = loaded.default
      return <Component {...props} slug="events" />
    },
  }),
  exact: true,
})

export const recordingsList = createRoute({
  path: '/:locale/events/recordings',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lives-list" */ 'pages/content/lives/list'),
    loading: loading,
    render(loaded, props) {
      let Component = loaded.default
      return <Component {...props} slug="recordings" />
    },
  }),
  exact: true,
})

export const myEventsList = createRoute({
  path: '/:locale/events/my-events',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "lives-my-events" */ 'pages/content/lives/my-events'
      ),
    loading: loading,
  }),
  exact: true,
})

export const archivedLivesList = createRoute({
  path: '/:locale/events/archived-events',
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "lives-archived-events" */ 'pages/content/lives/archived-events'
      ),
    loading: loading,
  }),
  exact: true,
})

export const livesView = createRoute({
  path: '/:locale/events/:id/:content_name',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lives-view" */ 'pages/content/lives/view'),
    loading: loading,
  }),
  exact: true,
})

export const livesViewNoTitle = createRoute({
  path: '/:locale/events/:id',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lives-view" */ 'pages/content/lives/view'),
    loading: loading,
  }),
  exact: true,
})

export const listsView = createRoute({
  path: '/:locale/lists/:id',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "lists-view" */ 'pages/content/lists/view'),
    loading: loading,
  }),
})

export const videosList = createRoute({
  path: '/:locale/videos',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "videos-list" */ 'pages/content/videos/list'),
    loading: loading,
  }),
  exact: true,
})

export const videosFilter = createRoute({
  path: '/:locale/videos/select/:filter/:name',
  component: videosList.component,
  exact: true,
})

export const videoView = createRoute({
  path: '/:locale/videos/:id/:content_name',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "videos-view" */ 'pages/content/videos/view'),
    loading: loading,
  }),
  exact: true,
})

export const videoViewNoTitle = createRoute({
  path: '/:locale/videos/:id',
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "videos-view" */ 'pages/content/videos/view'),
    loading: loading,
  }),
  exact: true,
})

export const notFoundLive = createRoute({
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "not-found" */ 'pages/content/lives/not-found'
      ),
    loading: loading,
  }),
})
