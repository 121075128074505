import { ApiAction } from 'redux-upgrader'
import { createNotification } from 'modules/notifications/actions'
import { makeQueryString, makePlainObject } from 'utils/api'
import { batch } from 'react-redux'
import Cookies from 'js-cookie'
import { UAE_REGULATED_CONTENT_LOCALE_KEY } from 'config/constants'

import { isServer } from 'store'

let memo = []
let timeout = null

const CACHE = {}

export default (config = {}) => {
  const {
    createPromise = ({ method, url, body }) => fetch(url, { method, body }),
    apiHost = '',
  } = config

  return ({ getState, dispatch }) => {
    return next => action => {
      if (!action.type && action.url) {
        const state = getState()
        const {
          types = new ApiAction('HTTP'),
          method = 'GET',
          search = {},
          body = {},
          prepare = data => data,
          customApiHost = apiHost,
          headers = {},
          hasRestrictedContent,
        } = action

        const searchParams = { ...search }

        action.pagination = state.common.pagination

        const request = {
          ...action,
          type: types.REQUEST,
        }

        const success = {
          ...action,
          type: types.SUCCESS,
        }

        const failure = {
          ...action,
          type: types.FAILURE,
        }

        searchParams.locale ||= state.common.locale

        if (action.url === '/api/terms_of_service') {
          searchParams.locale = 'en'
        }

        if (typeof hasRestrictedContent !== 'undefined') {
          const { restrictedContentAllowed } = getState().user

          const isWithRestrictedContent =
            restrictedContentAllowed ||
            (Cookies &&
              Cookies.get(UAE_REGULATED_CONTENT_LOCALE_KEY) === 'true')

          if (
            isWithRestrictedContent !== null ||
            isWithRestrictedContent !== undefined
          ) {
            searchParams.with_restricted_content = isWithRestrictedContent
          }
        }

        let url = makeQueryString(makePlainObject(searchParams), action.url)

        if (!url.match(/^http/)) {
          url = customApiHost + url
        }

        dispatch(request)

        const config = {
          ...action,
          url,
          types,
          method,
          search,
          body,
          headers: {
            Site: state.common.requestInfo.host,
            ...headers,
          },
        }

        if (!isServer && method === 'GET' && action.cache) {
          if (CACHE.hasOwnProperty(url)) {
            const response = CACHE[url]
            success.response = response
            success.data = prepare(response.body)
            clearTimeout(timeout)
            memo.push(success)
            timeout = setTimeout(() => {
              batch(() => {
                memo.forEach(action => dispatch(action))
                memo = []
              })
            }, 500)
            return Promise.resolve(response)
          }
        }

        return createPromise(config)
          .then(response => {
            if (!isServer && method === 'GET' && action.cache) {
              CACHE[url] = response

              setTimeout(() => {
                delete CACHE[url]
              }, action.cache)
            }
            success.response = response
            success.data = prepare(response.body)

            if (isServer) {
              dispatch(success)
            } else {
              clearTimeout(timeout)
              memo.push(success)
              timeout = setTimeout(() => {
                batch(() => {
                  memo.forEach(action => dispatch(action))
                  memo = []
                })
              }, 500)
            }

            return response
          })
          .catch(error => {
            failure.error = error
            dispatch(failure)

            if (isServer) {
              return error
            }

            if (
              error &&
              error.response &&
              error.response.body &&
              error.response.body.errors &&
              error.response.body.errors instanceof Array &&
              (error.response.error.method === 'POST' ||
                error.response.error.method === 'PATCH')
            ) {
              error.response.body.errors.forEach(({ code, message }) => {
                dispatch(
                  createNotification({
                    type: 'error',
                    children: state.common.strings[code] || message || 'Error',
                  })
                )
              })
            }

            throw error
          })
      }
      return next(action)
    }
  }
}
