import { frontloadConnect } from 'react-frontload'
import { isServer } from 'store'

function preloadFunc(func) {
  return props => {
    const { location } = props.history
    const haveSurf = location.state?.prevPage !== location.pathname
    const canPreload =
      document.readyState === 'complete' || isServer || haveSurf

    return canPreload ? func(props) : Promise.resolve()
  }
}

export default function frontload(Component) {
  return frontloadConnect(preloadFunc(Component.preload))(Component)
}
