import React, { Fragment } from 'react'
import Component from 'components/component'
import { DI } from 'modules'
// import FooterBots from './footer-bots'
import RejectionWarningModal from 'components/card-rejection/rejection-warning-modal'
import FooterSocial from './footer-social'
import FooterInfo from './footer-info'

export class Footer extends Component {
  state = {
    showModal: false,
  }

  render() {
    // const { strings } = this.props.common

    // const footer = strings['footer.dashboard'] || {}
    // const SocialsBlock = this.props.common.IsFooterSocials

    return (
      <footer
        className={this.classList(
          'footer'
          // !SocialsBlock && 'footer--with-bots'
        )}
      >
        {this.props.common.cardWarningModal && <RejectionWarningModal />}

        {/* {!SocialsBlock && (
          <Fragment>
            <div className="footer-top">
              <div className="iwrap">
                <div className="footer-up-to-date">
                  <p>{footer.desc}</p>
                </div>
                <FooterBots />
              </div>
            </div>
            <div className="footer-separator"></div>
          </Fragment>
        )} */}

        <FooterSocial />
        <FooterInfo />
      </footer>
    )
  }
}

export default DI(['common'])(Footer)
