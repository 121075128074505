import * as C from './consts'
import * as C_COMMON from '../common/consts'

export const getVideo = (id) => (d, g) => {
  d({
    type: C_COMMON.SET_PENDING_CONTENT,
    value: true,
  })
  
  return d({
    types: C.GET_VIDEO,
    id,
    url: `/api/content/video_projects/${id}`,
    cache: 999 * 1000,
  })
}

export function getList(search = {}) {
  return {
    types: C.GET_LIST,
    url: search.search
      ? '/api/content/video_projects/search'
      : '/api/content/video_projects',
    search: search,
    hasRestrictedContent: true,
  }
}

export const clearList = () => ({
  type: C.CLEAR_LIST_VIDEOS,
  value: [],
})

export function getDuration() {
  return {
    types: C.GET_DURATION,
    url: '/api/system/min_max_duration_video_projects',
  }
}

export function getRelatedList(search = {}) {
  return {
    types: C.GET_RELATED_LIST,
    url: '/api/content/video_projects/related_video_projects',
    search,
    cache: 999 * 1000,
    hasRestrictedContent: true,
  }
}

export function getSuggests(search) {
  return {
    types: C.GET_SUGGESTS,
    url: '/api/content/video_projects/suggests',
    search,
    hasRestrictedContent: true,
  }
}

export function clearSuggests(search) {
  return {
    type: C.GET_SUGGESTS.RESET,
  }
}

export function getAvailableFormats(
  id,
  { agreement_id, branded },
  isSignedIn = true
) {
  return {
    types: C.GET_AVAILABLE_FORMATS,
    url: `/api/content/video_projects/${id}/videos/current${
      isSignedIn ? '' : '_guest'
    }_available_formats`,
    search: { agreement_id, branded },
  }
}

export function downloadVideo(params) {
  return {
    types: C.DOWNLOAD_VIDEO,
    method: 'POST',
    url: `/api/content/video_projects/${params.project_id}/videos/download`,
    search: params,
  }
}

export function previewDownloadVideo(params) {
  return {
    types: C.PREVIEW_DOWNLOAD_VIDEO,
    method: 'POST',
    url: `${params}${params.repeated ? 'repeated_downloads' : 'download'}`,
  }
}

export function requestWorldExclusive(id) {
  return {
    types: C.REQUEST_WORLD_EXCLUSIVE,
    method: 'POST',
    url: `/api/content/video_projects/${id}/videos/request_world_exclusive`,
  }
}

export function setPaymentAlert(alert) {
  return {
    type: C.SET_PAYMENT_ALERT,
    alert,
  }
}

export function sendStatsHit(search_id, video, event_type) {
  if (search_id) {
    return {
      types: C.SEND_STATS_HIT,
      url: '/api/content/video_projects/search_statistics',
      method: 'POST',
      body: {
        search_id,
        video,
        event_type,
      },
    }
  } else {
    return {
      type: C.SEND_STATS_HIT.FAILURE,
    }
  }
}
