import * as config from 'config'
import { ConnectedRouter } from 'connected-react-router'
import init from 'init'
// import BrowserRouter from 'react-router-dom/BrowserRouter';
import React from 'react'
import { ActionCableProvider } from 'react-actioncable-provider'
import { hydrate } from 'react-dom'
import { Frontload } from 'react-frontload'
import Loadable from 'react-loadable'
import { Provider } from 'react-redux'
import App from './app'
import getAcceptedCookies from './helpers/cookies'
import createStore from './store'

const { store, history } = createStore()

Loadable.preloadReady().then(() => {
  const cookiesList = getAcceptedCookies()

  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Frontload noServerRender={true}>
          <ActionCableProvider url={config.WS_URI}>
            <App />
          </ActionCableProvider>
        </Frontload>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
    () => {
      init(cookiesList, store)
    }
  )
})

history.listen((state, method) => {
  if (state.hasOwnProperty('state')) {
    const { scrollToTop = true } = state.state || {}
    if (scrollToTop) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
})

if (module.hot) {
  module.hot.accept()
}

// OfflinePluginRuntime.install();
