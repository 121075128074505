import ReactGA from 'react-ga'
import * as amplitude from '@amplitude/analytics-browser'
import fingerprint from 'utils/fingerprint'
import { loadScript } from 'helpers/document'
import { isProd, AMPLITUDE_HOST, FB_APP_ID } from 'config'

export function gtag() {
  window.dataLayer.push(arguments)
}

export const pageViewTrackingEnrichment = store => {
  return {
    name: 'page-view-tracking-enrichment',
    type: 'enrichment',
    setup: async () => undefined,
    execute: async event => {
      const { user } = store ? store.getState() : {}

      if (event.event_type === 'page_view') {
        const renamed = (Object.keys(event.event_properties) || []).reduce(
          (ak, k) => {
            return {
              ...ak,
              [k
                .replace('[Amplitude] ', '')
                .replaceAll(' ', '_')
                .toLocaleLowerCase()]: event.event_properties[k],
            }
          },
          {}
        )

        return {
          ...event,
          event_properties: {
            ...renamed,
            user_id: user?.currentUser?.payload?.id,
            company_id: user?.currentUser?.payload?.company_id,
          },
        }
      }

      return event
    },
  }
}

export default function init(cookiesAccepted, store) {
  if (cookiesAccepted) {
    const { functional, marketing, performance, strictlyNecessary } =
      cookiesAccepted

    if (strictlyNecessary) {
      // initPushServiceWorker()
      initOther()
    }

    if (functional) {
      fingerprint()
    }

    if (marketing) {
      /*
          Выключено по просьбе Мити 23 08 2022
          initTaboola();
        */
      // initFB()
    }

    if (performance) {
      if (isProd) {
        initGoogleTagManager()
      }

      // initGA()
    }
  }

  amplitude.add(pageViewTrackingEnrichment(store))

  amplitude.init('d550ebcbf4ede5affc15fd6d3915e612', {
    defaultTracking: {
      attribution: false,
      pageViews: {
        eventType: 'page_view',
      },
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
    identityStorage: 'localStorage',
    serverUrl: AMPLITUDE_HOST,
  })
}

function initGoogleTagManager() {
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-ZB7KLZ7TCK', () => {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', 'G-ZB7KLZ7TCK')
  })
}

function initPushServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        if (!isProd) {
          console.log('Registration successful, scope is:', registration.scope)
        }
      })
      .catch(function (err) {
        if (!isProd) {
          console.log('Service worker registration failed, error:', err)
        }
      })
  }
}

function initGA() {
  ReactGA.initialize('UA-125686506-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

function initFB(prod) {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: FB_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.3',
    })
  }
  ;(function (d, s, id) {
    let js
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js#version=v3.3'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  fbq('init', '1294269254296168')
  fbq('track', 'PageView')
}

function initOther() {
  if (
    /iPad|iPhone|iPod|Macintosh|MacIntel|MacPPC|Mac68K/.test(
      navigator.userAgent
    ) &&
    !window.MSStream
  ) {
    document.body.classList.add('_ios')
  }
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    document.body.classList.add('_android')
  }
  if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    document.body.classList.add('_firefox')
  }
  document.body.classList.add('_loaded')

  document.oncopy = function () {
    if (!window.isAdmin) {
      const bodyElement = document.body
      const selection = window.getSelection()

      if (selection.toString() === window.withoutCopyright) {
        delete window.withoutCopyright
      } else {
        const range = selection.getRangeAt(0)
        const { href } = document.location
        const copyright = ` ${window.copied_text} ${href}`
        const text = selection + copyright
        const divElement = document.createElement('div')

        divElement.style.position = 'absolute'
        divElement.style.left = '-99999px'

        const textNode = document.createTextNode(text) // создал текстовый узел
        divElement.appendChild(textNode) // и добавил его
        bodyElement.appendChild(divElement)
        selection.selectAllChildren(divElement)
        setTimeout(function () {
          bodyElement.removeChild(divElement)
          selection.removeAllRanges()
          selection.addRange(range)
        }, 0)
      }
    }
  }
}

export const TABOOLA_ID = 1348520
function initTaboola() {
  window._tfa = window._tfa || []
  window._tfa.push({ notify: 'event', name: 'page_view', id: TABOOLA_ID })
  !(function (t, f, a, x) {
    if (!document.getElementById(x)) {
      t.async = 1
      t.src = a
      t.id = x
      f.parentNode.insertBefore(t, f)
    }
  })(
    document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1348520/tfa.js',
    'tb_tfa_script'
  )
}
