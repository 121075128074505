import * as C from '../consts'
import { REGISTRATION_BY_OTP } from '../consts'

/* SESSION */

export function signIn(user) {
  user.email = user?.email?.toLowerCase()

  return {
    types: C.SIGN_IN,
    method: 'POST',
    url: '/users/sign_in.json',
    body: { user },
  }
}

export const adminVerify = token => {
  return {
    types: C.ADMIN_VERIFY,
    method: 'POST',
    url: '/api/admin/users/admins/token_verifications',
    body: token,
  }
}

export const verifyOTP = confirmation_token => {
  return {
    types: C.VERIFY_OTP,
    method: 'GET',
    url: '/api/users/check_confirmation_token',
    search: { confirmation_token },
  }
}

export const registrationOfOTP = email => {
  return {
    types: C.REGISTRATION_BY_OTP,
    method: 'POST',
    url: '/api/users/registrations',
    body: { email },
  }
}

export function signOut() {
  return {
    types: C.SIGN_OUT,
    method: 'DELETE',
    url: '/users/sign_out.json',
  }
}

export const sendResetPasswordInstructions = email => ({
  types: C.SEND_RESET_PASSWORD_INSTRUCTIONS,
  method: 'POST',
  url: '/api/users/password_instructions',
  body: { email },
})

export const updatePassword = ({
  reset_password_token,
  password,
  password_confirmation,
}) => {
  const user = { reset_password_token, password, password_confirmation }
  return {
    types: C.UPDATE_PASSWORD,
    method: 'PATCH',
    url: '/users/password',
    body: { user },
  }
}

/*  / SESSION */
