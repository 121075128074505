import React from 'react'
import { DI } from 'modules'

export function FooterSocial(props) {
  const { links, rss, title } = props.common.strings['footer.social'] || []

  return (
    <div className="footer-subscribe">
      <div className="iwrap">
        <div class="footer-subscribe__rss subscribe-rss">
          <div class="subscribe-rss__title">{rss}</div>
          <div class="subscribe-rss__block">
            <a class="subscribe-rss__link" href="https://rss.viory.video/">
              https://rss.viory.video/
            </a>
          </div>
        </div>
        <div className="footer-subscribe__social footer-new-social">
          <div class="footer-new-social__title">{title}</div>
          <div class="footer-new-social__icons">
            {links?.map((item, index) => (
              <a
                key={index}
                href={item.link}
                className={`footer-new-icon footer-new-icon--${item.ico}`}
                target="_blank"
                rel="noreferrer"
              ></a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DI([])(FooterSocial)
