import * as C from './consts'

export function getTags() {
  return {
    types: C.GET_TAGS,
    url: `/api/content/tags`,
    cache: 1000 * 999,
  }
}

export const getTag = id => ({
  types: C.GET_TAG,
  url: `/api/content/tags/${id}`,
})

export function getEntityTag({ slug, page = 1, per_page = 12 }) {
  return {
    types: C.GET_ENTITY_TAG,
    url: `/api/content/tags/landings/${slug}`,
    search: { page, per_page },
  }
}

export function getFilteredEntityTag(params = {}) {
  return {
    types: C.GET_ENTITY_FILTERED_TAG,
    url: params.search
      ? '/api/content/video_projects/search'
      : '/api/content/video_projects',
    search: params,
    hasRestrictedContent: true,
  }
}

export const setListViewTypeLanding = value => ({
  type: C.SET_LIST_VIEW_TYPE_LANDING,
  value,
})

export function followTag(tag_id) {
  return {
    types: C.FOLLOW_TAG,
    url: `/api/content/tags/${tag_id}/subscriptions`,
    method: 'POST',
  }
}

export function unfollowTag(tag_id) {
  return {
    types: C.FOLLOW_TAG,
    url: `/api/content/tags/${tag_id}/subscriptions`,
    method: 'DELETE',
  }
}
