import React from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import * as routes from 'config/routes'

function FooterInfoItem(props) {
  return (
    <li className="footer-info-menu__item">
      <Link to={props.link} className="finf-contacts-item__link">
        {props.text}
      </Link>
    </li>
  )
}

export function FooterInfo(props) {
  const { strings } = props.common
  return (
    <div className="footer-info">
      <div className="iwrap">
        <div class="footer-info__top">
          <div class="footer-info-contacts">
            <div class="footer-info-contacts__item finf-contacts-item">
              <div class="finf-contacts-item__title">
                {strings['page.server_error.client_service']}
              </div>
              <a class="finf-contacts-item__link" href="mailto:cs@viory.video">
                cs@viory.video
              </a>
            </div>
            <div class="footer-info-contacts__item finf-contacts-item">
              <div class="finf-contacts-item__title">
                {strings['page.server_error.submit']}
              </div>
              <a
                class="finf-contacts-item__link"
                href="mailto:sendvideo@viory.video"
              >
                sendvideo@viory.video
              </a>
            </div>
            <div class="footer-info-contacts__item finf-contacts-item">
              <div class="finf-contacts-item__title">
                {strings['page.server_error.press']}
              </div>
              <a class="finf-contacts-item__link" href="mailto:pr@viory.video">
                pr@viory.video
              </a>
            </div>
            <div class="footer-info-contacts__item finf-contacts-item">
              <div class="finf-contacts-item__title">
                {strings['uae.claim_content.linktext']}
              </div>
              <a
                class="finf-contacts-item__link"
                href='mailto:help@viory.video'
              >
                help@viory.video
              </a>
            </div>
          </div>
        </div>

        <div class="footer-info__bottom">
          <div class="footer-info-menu">
            <ul class="footer-info-menu__section">
              <FooterInfoItem
                link={routes.livesList.getPath()}
                text={strings['header.menu.live']}
              />
              <FooterInfoItem
                link={routes.videosList.getPath()}
                text={strings['header.menu.vod']}
              />
            </ul>

            <ul class="footer-info-menu__section">
              <FooterInfoItem
                link={routes.about.getPath()}
                text={strings['footer.link.about']}
              />
              <FooterInfoItem
                link={routes.choosePlan.getPath()}
                text={strings['header.menu.pricing']}
              />
            </ul>

            <ul class="footer-info-menu__section">
              <FooterInfoItem
                link={routes.companyDetails.getPath()}
                text={strings['footer.link.company-details']}
              />
              <FooterInfoItem
                link={routes.tos.getPath()}
                text={strings['footer.link.tos']}
              />
            </ul>

            <ul class="footer-info-menu__section">
              <FooterInfoItem
                link={routes.privacyPolicy.getPath()}
                text={strings['footer.link.privacy']}
              />
              <FooterInfoItem
                link={routes.cookiePolicy.getPath()}
                text={strings['footer.link.cookie']}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DI([])(FooterInfo)
