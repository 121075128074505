import React, { Fragment } from 'react'
import Component from 'components/component'
import { NavLink, Route, Switch } from 'react-router-dom'
import { Link } from 'components/link'
import { DI } from 'modules'

import HeaderProfile from './header-profile'
import HeaderMobileMenu from './header-mobile-menu'
import HeaderSearch from './header-search'
import Newsletter from './newsletter'
import LinkA from './link-a'
import {
  dashboard,
  livesList,
  livesView,
  myEventsList,
  videosFilter,
  videosList,
  videoView,
} from 'config/routes'
import { PRJ_ENV, isProd } from 'config'

const { component, getPath, ...dashboardRoute } = dashboard

const Empty = () => null

export class HeaderMain extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
  }

  static defaultProps = {
    openSignInModal() {},
  }

  items = []

  state = {
    selectedEvent: null,
    reducedMenu: {
      inited: false,
      main: [],
      dots: [],
    },
    dotsPosition: 0,
  }

  componentDidMount() {
    setTimeout(() => {
      this.reduceMenu()
    }, 500)
    window.addEventListener('resize', this.reduceMenu)

    if (this.props.onChangeFixed) {
      this.setFloatFixed()
      window.addEventListener('resize', this.setFloatFixed)
      window.addEventListener('scroll', this.setFloatFixed)
    }

    // window.setTimeout(() => {
    //     const { actions, user } = this.props;

    //     if (user.isSignedIn && !user.currentUser.payload.newsletter_notify){
    //         actions.common.openNewsletterModal();
    //     }
    // }, 1000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setFloatFixed)
    window.removeEventListener('scroll', this.setFloatFixed)

    window.clearTimeout(this.reduceTimeout)
    window.removeEventListener('resize', this.reduceMenu)
  }

  string = (key) => this.props.common.strings[key]

  get isReverse() {
    return this.props.common.locale === 'ar'
  }

  setFloatFixed = () => {
    const floatFixed =
      window.scrollY && window.scrollY >= this.node.current.offsetTop

    this.props.onChangeFixed(floatFixed)
  }

  reduceMenu = () => {
    if (this.menu && window && this.items.length > 0) {
      const menuRect = this.menu.getBoundingClientRect()
      const items = this.isReverse ? this.items.reverse() : this.items
      let dotsPosition = 0

      let reducedMenu = items.reduce(
        (result, item, index) => {
          if (item) {
            const itemRect = item.getBoundingClientRect()
            const dotsWidth = items.length - 1 === index ? 0 : 47
            const counter = this.isReverse ? items.length - 1 - index : index

            function distributor(isDots) {
              if (isDots) {
                result.dots.push(counter)
              } else {
                result.main.push(counter)
              }
            }

            if (this.isReverse) {
              distributor(itemRect.left + dotsWidth < menuRect.left)
            } else {
              distributor(
                itemRect.left + itemRect.width + dotsWidth >
                  menuRect.left + menuRect.width
              )
            }
          }
          return result
        },
        {
          inited: true,
          main: [],
          dots: [],
        }
      )

      if (reducedMenu.dots.length > 0) {
        const currHiddenNodeIndex = this.isReverse
          ? items.length - reducedMenu.dots.length
          : reducedMenu.dots[0]

        const protoEl = (this.isReverse ? [...items].reverse() : items)[
          currHiddenNodeIndex
        ]
        if (protoEl) {
          const protoPos = protoEl.getBoundingClientRect()
          dotsPosition =
            protoPos[this.isReverse ? 'right' : 'left'] - menuRect.left

          if (this.isReverse) {
            const itemStyle = window.getComputedStyle(protoEl)
            const intent = parseInt(itemStyle.getPropertyValue('margin-right'))

            dotsPosition -= intent / 2
          }
        }
      }

      this.setState({ reducedMenu, dotsPosition })
    }
  }

  toggleMobileMenu = (ev) => {
    ev.preventDefault()
    this.props.actions.common.toggleMobileMenu()
  }

  closeNewsletterModal = (ev) => {
    ev && ev.preventDefault()
    this.props.actions.common.closeNewsletterModal()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.common.locale !== this.props.common.locale) {
      setTimeout(() => {
        this.reduceMenu()
      }, 500)
    }
  }

  _renderDots() {
    if (this.state.reducedMenu.dots.length === 0) {
      return null
    }
    return (
      <div
        style={{
          position: 'absolute',
          left: this.state.dotsPosition,
          top: 2,
          marginLeft: 0,
        }}
      >
        <a
          href="#"
          className="menu__link menu__link--more"
          onClick={(e) => {
            e.preventDefault()
            this.setState({ menuMoreOpen: true })
          }}
          onBlur={() => {
            this.setState({ menuMoreOpen: false })
          }}
        >
          <span className="menu-more" />
        </a>
        <div
          className="menu-submenu"
          style={{
            opacity: this.state.menuMoreOpen ? '1' : '0',
            visibility: this.state.menuMoreOpen ? 'visible' : 'hidden',
          }}
        >
          >
          <ul className="menu-submenu__list">
            {this.props.menu.mainMenu
              .filter((_, index) => this.state.reducedMenu.dots.includes(index))
              .map((subitem) => (
                <li key={subitem.link} className="menu-submenu__item">
                  <LinkA
                    link={subitem.link}
                    className={this.classList(
                      'menu-submenu__link',
                      subitem.active && '_active'
                    )}
                  >
                    {subitem.title}
                  </LinkA>
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }

  render() {
    const isSignedIn = this.props.user.isSignedIn
    const { strings, locale, newsletterModal, searchParams, isMobile } =
      this.props.common
    const { with_international_mix: mix } = this.props.common.searchParams || {}
    const search = mix ? `?with_international_mix=${mix}` : ''

    return (
      <Fragment>
        <div className="header-main" ref={this.node}>
          <div className="iwrap">
            <div className="header-inner" ref={(el) => (this.inner = el)}>
              <Link
                to={'/' + search}
                className="header-logo"
                ref={(el) => (this.left = el)}
              >
                <span
                  className="header-logo__img"
                  style={
                    this.isReverse
                      ? { marginRight: '-8px' }
                      : { marginLeft: '-15px' }
                  }
                >
                  {strings['site.title']}
                </span>
                {/* {!isProd && (
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#fff',
                      paddingTop: '8px',
                    }}
                  >
                    {PRJ_ENV}
                  </div>
                )} */}
              </Link>
              <nav
                className="menu _vis"
                ref={(el) => (this.menu = el)}
                style={{ width: '100%' }}
              >
                <ul className="menu-list">
                  {this.props.menu.mainMenu
                    .filter((item) => item.title)
                    .map((item, index) => {
                      const hidden = this.state.reducedMenu.dots.includes(index)
                      return (
                        <Fragment key={item.link}>
                          <li
                            className="menu-item"
                            ref={(el) => {
                              this.items[index] = el
                            }}
                            style={
                              hidden
                                ? {
                                    visibility: 'hidden',
                                    pointerEvents: 'none',
                                  }
                                : undefined
                            }
                          >
                            <LinkA
                              link={item.link}
                              searchParams={searchParams}
                              className={this.classList(
                                'menu__link',
                                item.active && '_active'
                              )}
                            >
                              {item.title}
                            </LinkA>
                            {Boolean(
                              item.children.length &&
                                item.children.filter((item) => item.title)
                                  .length > 1
                            ) && (
                              <div className="menu-submenu">
                                <ul className="menu-submenu__list">
                                  {item.children
                                    .filter((subitem) => subitem.title)
                                    .map((subitem) => (
                                      <li
                                        key={subitem.link}
                                        className="menu-submenu__item"
                                      >
                                        <LinkA
                                          link={subitem.link}
                                          searchparams={searchParams}
                                          className={this.classList(
                                            'menu-submenu__link',
                                            subitem.active && '_active'
                                          )}
                                        >
                                          {subitem.title}
                                        </LinkA>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}
                          </li>
                        </Fragment>
                      )
                    })}
                </ul>
                {this._renderDots()}
              </nav>
              <Switch>
                <Route {...dashboard} component={Empty} />
                <Route {...videosList} component={Empty} />
                <Route {...livesList} component={Empty} />

                {!isMobile && (
                  <Fragment>
                    <Route {...videoView} component={Empty} />
                    <Route {...livesView} component={Empty} />
                  </Fragment>
                )}

                <Route {...myEventsList} component={Empty} />
                <Route {...videosFilter} component={Empty} />
                <Route component={HeaderSearch} />
              </Switch>
              {/*<a href="#" className="header__search" />*/}
              <NavLink
                to={`/${locale}/user`}
                className={this.classList(
                  'header__mm',
                  this.props.common.mobileMenuOpen && '_opened'
                )}
                onClick={this.toggleMobileMenu}
              >
                Profile
              </NavLink>
              {isSignedIn ? (
                <HeaderProfile />
              ) : (
                <div className="header-profile" ref={(el) => (this.right = el)}>
                  <div className="header-profile-link">
                    <a
                      href="#"
                      className="header-profile__link"
                      onClick={this.props.openSignInModal}
                      data-action-name="header button"
                    >
                      {this.string('header.profile.signin')}
                    </a>
                  </div>
                  <div className="header-profile-link">
                    <a
                      href="#"
                      className="header-profile__link"
                      onClick={this.props.openSignUpModal}
                      data-action-name="header button"
                    >
                      {this.string('header.profile.signup')}
                    </a>
                  </div>
                </div>
              )}
            </div>
            {!this.props.common.landingPage && this.props.onChangeFixed && (
              <Route
                {...dashboardRoute}
                render={() => (
                  <span className="header__slogan header__slogan--all">
                    {strings['header.slogan']}
                  </span>
                )}
              />
            )}
          </div>
        </div>
        <HeaderMobileMenu
          openSignInModal={this.props.openSignInModal}
          openSignUpModal={this.props.openSignUpModal}
        />

        {newsletterModal && (
          <Newsletter
            onClose={this.closeNewsletterModal}
            openNewsletterModal={this.closeNewsletterModal}
          />
        )}
      </Fragment>
    )
  }
}

export default DI(['user', 'menu'])(HeaderMain)
