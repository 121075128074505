import React, { Fragment } from 'react'
import { DI } from 'modules'
// import DEPRECATED_moment from 'moment-timezone';
import { DateTime } from 'luxon'
import TosItem from './tos-item'

class ToS extends React.Component {
  constructor(props) {
    super(props)
  }

  closeTosModal = ev => {
    ev && ev.preventDefault()
    this.props.actions.common.closeTosModal()
    this.props.actions.user.signOut()
  }
  confirmTosModal = ev => {
    ev && ev.preventDefault()
    this.props.actions.common.closeTosModal()
    this.props.actions.user.getCurrentUser().catch(() => {})
  }

  render() {
    const isSignedIn = this.props.user.isSignedIn
    const { locale, tosModal } = this.props.common

    const tosLocale = this.props.tos.tosInfo.payload.locale
    const tosUpdated = this.props.tos.tosInfo.payload.version_created_at

    const termsAgreed =
      this.props.user.currentUser.payload.terms_accepted_at &&
      this.props.user.currentUser.payload.terms_accepted_at[locale]
    const acceptDate =
      DateTime.fromISO(tosUpdated) > DateTime.fromISO(termsAgreed)

    if (isSignedIn && acceptDate && tosLocale === locale) {
      return (
        <Fragment>
          <TosItem
            close={this.closeTosModal}
            confirm={this.confirmTosModal}
            openTosModal={this.closeTosModal}
          />
        </Fragment>
      )
    } else {
      return null
    }
  }
}

export default DI(['user', 'tos'])(ToS)
